.services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5rem;
  margin-top: 10rem;

  @media only screen and (max-width: 1920px) {
    margin-top: 4rem;
  }

  @media only screen and (max-width: 1024px) and (max-height: 1366px) {
    margin-top: 8rem;
  }

  @media only screen and (max-width: 1024px) and (max-height: 640px) {
    margin-top: 12rem;
  }

  @media only screen and (max-width: 980px) {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 8rem;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 5rem;
  }

  @media only screen and (max-width: 460px) {
    margin-top: 8rem;
  }

  // Iphone X
  @media only screen and (max-width: 414px) {
    margin-top: 5rem;
  }

  @media only screen and (max-width: 355px) {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 320px) {
    margin-top: 10rem;
  }

  &__heading {
    display: flex;
    align-self: flex-start;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;

    @media only screen and (max-width: 980px) {
      margin-left: 1rem;
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 700px) {
      margin-bottom: 0.5rem;
      margin-left: 0rem;
    }

    @media only screen and (max-width: 600px) {
      margin-bottom: 0.5rem;
      margin-left: 7.5rem;
    }

    @media only screen and (max-width: 460px) {
      margin-left: 1.7rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-bottom: 0.5rem;
    }

    @media only screen and (max-width: 320px) {
      margin-left: 1rem;
      font-size: .5rem;
    }
  }

  &__section {
    max-height: 100vh;
  }
}

.s-gallery {
  display: grid;
  grid-template-columns: repeat(3, 23rem);
  grid-template-rows: 16rem 16rem;

  @media only screen and (max-width: 1180px) {
    grid-template-columns: repeat(3, 20rem);
    grid-template-rows: 14rem 14rem;
  }

  @media only screen and (max-width: 980px) {
    grid-template-columns: repeat(3, 17rem);
    grid-template-rows: 14rem 14rem;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: repeat(3, 14rem);
    grid-template-rows: 11rem 11rem;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(3, 12rem);
    grid-template-rows: 10rem 10rem;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // Iphone X
  @media only screen and (max-width: 414px) {
    grid-template-columns: repeat(3, 6rem);
    grid-template-rows: 5rem 5rem;
  }

  @media only screen and (max-width: 320px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__1 {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    background-image: url("../../images/lightbulb01.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(117, 117, 117);

    @media only screen and (max-width: 500px) {
      display: flex;
      width: 15rem;
      height: 8rem;
      background-position-y: -20px;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      height: 14rem;
    }

    @media only screen and (max-width: 460px) {
      width: 18rem;
      height: 10rem;
    }

    @media only screen and (max-width: 320px) {
      width: 14rem;
      height: 8rem;
    }

    &:hover {
      background-image: none;
      background-color: black;
      transition: 1s ease;
      cursor: context-menu;
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 4rem;
      color: white;
      font-size: 0.9rem;
      opacity: 0;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        padding: 1rem 2rem;
      }

      @media only screen and (max-width: 700px) {
        padding: 1rem 1rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.8rem;
        padding: 2rem 4rem;
      }

      @media only screen and (max-width: 460px) {
        font-size: 0.6rem;
        padding: 2rem 3rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__2 {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    background-image: url("../../images/coffecup01.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(117, 117, 117);

    @media only screen and (max-width: 500px) {
      display: flex;
      width: 15rem;
      height: 8rem;
      background-position-y: -20px;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      height: 14rem;
    }

    @media only screen and (max-width: 460px) {
      width: 18rem;
      height: 10rem;
    }

    @media only screen and (max-width: 320px) {
      width: 14rem;
      height: 8rem;
    }

    &:hover {
      background-image: none;
      background-color: black;
      transition: 1s ease;
      cursor: context-menu;
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 4rem;
      color: white;
      font-size: 0.9rem;
      opacity: 0;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        padding: 1rem 2rem;
      }

      @media only screen and (max-width: 700px) {
        padding: 1rem 1rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.8rem;
        padding: 2rem 4rem;
      }

      @media only screen and (max-width: 460px) {
        font-size: 0.6rem;
        padding: 2rem 3rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__3 {
    grid-column: 3 / 3;
    grid-row: 1 / 1;
    background-image: url("../../images/cubes01.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(117, 117, 117);

    @media only screen and (max-width: 500px) {
      display: flex;
      width: 15rem;
      height: 8rem;
      background-position-y: -50px;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      height: 14rem;
    }

    @media only screen and (max-width: 460px) {
      width: 18rem;
      height: 10rem;
    }

    @media only screen and (max-width: 320px) {
      width: 14rem;
      height: 8rem;
    }

    &:hover {
      background-image: none;
      background-color: black;
      transition: 1s ease;
      cursor: context-menu;
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 4rem;
      color: white;
      font-size: 0.9rem;
      opacity: 0;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        padding: 1rem 2rem;
      }

      @media only screen and (max-width: 700px) {
        padding: 1rem 1rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.8rem;
        padding: 2rem 4rem;
      }

      @media only screen and (max-width: 460px) {
        font-size: 0.6rem;
        padding: 2rem 3rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__4 {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    background-image: url("../../images/brush01.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(117, 117, 117);

    @media only screen and (max-width: 500px) {
      display: flex;
      width: 15rem;
      height: 8rem;
      background-position-y: -20px;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      height: 14rem;
    }

    @media only screen and (max-width: 460px) {
      width: 18rem;
      height: 10rem;
    }
    @media only screen and (max-width: 320px) {
      width: 14rem;
      height: 8rem;
    }

    &:hover {
      background-image: none;
      background-color: black;
      transition: 1s ease;
      cursor: context-menu;
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 4rem;
      color: white;
      font-size: 0.9rem;
      opacity: 0;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        padding: 1rem 2rem;
      }

      @media only screen and (max-width: 700px) {
        padding: 1rem 1rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.8rem;
        padding: 2rem 4rem;
      }

      @media only screen and (max-width: 460px) {
        font-size: 0.6rem;
        padding: 2rem 3rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__5 {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    background-image: url("../../images/keyboard01.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(117, 117, 117);

    @media only screen and (max-width: 500px) {
      display: flex;
      width: 15rem;
      height: 8rem;
      background-position-y: -20px;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      height: 14rem;
    }

    @media only screen and (max-width: 460px) {
      width: 18rem;
      height: 10rem;
    }

    @media only screen and (max-width: 320px) {
      width: 14rem;
      height: 8rem;
      }

    &:hover {
      background-image: none;
      background-color: black;
      transition: 1s ease;
      cursor: context-menu;
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 4rem;
      color: white;
      font-size: 0.9rem;
      opacity: 0;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        padding: 1rem 2rem;
      }

      @media only screen and (max-width: 700px) {
        padding: 1rem 1rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.8rem;
        padding: 2rem 4rem;
      }

      @media only screen and (max-width: 460px) {
        font-size: 0.6rem;
        padding: 2rem 3rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__6 {
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    background-image: url("../../images/balloon.png");
    background-position: 5% 55%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(117, 117, 117);

    @media only screen and (max-width: 500px) {
      display: flex;
      width: 15rem;
      height: 8rem;
      background-position-y: -170px;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      height: 14rem;
    }

    @media only screen and (max-width: 460px) {
      width: 18rem;
      height: 10rem;
    }

    @media only screen and (max-width: 320px) {
      width: 14rem;
      height: 8rem;
      }

    &:hover {
      background-image: none;
      background-color: black;
      transition: 1s ease;
      cursor: context-menu;
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 4rem;
      color: white;
      font-size: 0.9rem;
      opacity: 0;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        padding: 1rem 2rem;
      }

      @media only screen and (max-width: 700px) {
        padding: 1rem 1rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 0.8rem;
        padding: 2rem 4rem;
      }

      @media only screen and (max-width: 460px) {
        font-size: 0.6rem;
        padding: 2rem 3rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.s-cards {
  display: grid;
  grid-template-columns: repeat(4, 18rem);
  grid-template-rows: 8rem;

  @media only screen and (max-width: 1180px) {
    grid-template-columns: repeat(4, 16rem);
    grid-template-rows: 6rem;
  }

  @media only screen and (max-width: 980px) {
    grid-template-columns: repeat(4, 13.5rem);
    grid-template-rows: 6rem;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: repeat(4, 11rem);
    grid-template-rows: 4rem;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(4, 9rem);
    grid-template-rows: 3rem;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // Iphone X
  @media only screen and (max-width: 414px) {
    grid-template-columns: repeat(4, 4.5rem);
    grid-template-rows: 3rem;
    margin-top: 1rem;
  }

  &__1 {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // Iphone X
    @media only screen and (max-width: 600px) {
      grid-column: 1 / 1;
      grid-row: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    &--icon {
      background-image: url("../../images/eye01.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 4rem;
      width: 4rem;

      @media only screen and (max-width: 850px) {
        height: 2.5rem;
        width: 2.5rem;
      }

      &:hover {
        background-image: url("../../images/eye02.png");
        transition: ease;
        cursor: crosshair;
      }
    }
  }

  &__2 {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // Iphone X
    @media only screen and (max-width: 600px) {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    &--icon {
      background-image: url("../../images/cross.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 4rem;
      width: 4rem;

      @media only screen and (max-width: 850px) {
        height: 2.5rem;
        width: 2.5rem;
      }

      &:hover {
        background-image: url("../../images/plus.png");
        transition: ease;
        cursor: crosshair;
      }
    }
  }

  &__3 {
    grid-column: 3 / 3;
    grid-row: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // Iphone X
    @media only screen and (max-width: 600px) {
      grid-column: 1 / 1;
      grid-row: 3 / 3;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    &--icon {
      background-image: url("../../images/line.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 4rem;
      width: 4rem;

      @media only screen and (max-width: 850px) {
        height: 2.5rem;
        width: 2.5rem;
      }

      &:hover {
        background-image: url("../../images/circle.png");
        transition: ease;
        cursor: crosshair;
      }
    }
  }

  &__4 {
    grid-column: 4 / 4;
    grid-row: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // Iphone X
    @media only screen and (max-width: 600px) {
      grid-column: 1 / 1;
      grid-row: 4 / 4;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    &--icon {
      background-image: url("../../images/arrows.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 4rem;
      width: 4rem;

      @media only screen and (max-width: 850px) {
        height: 2.5rem;
        width: 2.5rem;
      }

      &:hover {
        background-image: url("../../images/arrow.png");
        transition: ease;
        cursor: crosshair;
      }
    }
  }

  &__5 {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding: 0 2rem;
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 500;

    @media only screen and (max-width: 1180px) {
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.6rem;
    }

    @media only screen and (max-width: 850px) {
      font-size: 0.5rem;
    }

    @media only screen and (max-width: 700px) {
      line-height: 1.2;
      padding: 0 1rem;
    }

    @media only screen and (max-width: 600px) {
      text-align: left;
      width: 26rem;
      font-size: 0.7rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 500px) {
      width: 15rem;
    }

    @media only screen and (max-width: 460px) {
      width: 19rem;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
      font-size: .6rem;
    }
  }

  &__6 {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding: 0 2rem;
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 500;

    @media only screen and (max-width: 1180px) {
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.6rem;
    }

    @media only screen and (max-width: 850px) {
      font-size: 0.5rem;
    }

    @media only screen and (max-width: 700px) {
      line-height: 1.2;
      padding: 0 1rem;
    }

    @media only screen and (max-width: 600px) {
      text-align: left;
      width: 26rem;
      font-size: 0.7rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
      width: 15rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 460px) {
      width: 19rem;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
      font-size: .6rem;
    }
  }

  &__7 {
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding: 0 2rem;
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 500;

    @media only screen and (max-width: 1180px) {
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.6rem;
    }

    @media only screen and (max-width: 850px) {
      font-size: 0.5rem;
    }

    @media only screen and (max-width: 700px) {
      line-height: 1.2;
      padding: 0 1rem;
    }

    @media only screen and (max-width: 600px) {
      text-align: left;
      width: 26rem;
      font-size: 0.7rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
      width: 15rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 460px) {
      width: 19rem;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
      font-size: .6rem;
    }
  }

  &__8 {
    grid-column: 4 / 4;
    grid-row: 2 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding: 0 2rem;
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 500;

    @media only screen and (max-width: 1180px) {
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.6rem;
    }

    @media only screen and (max-width: 850px) {
      font-size: 0.5rem;
    }

    @media only screen and (max-width: 700px) {
      line-height: 1.2;
      padding: 0 1rem;
    }

    @media only screen and (max-width: 600px) {
      text-align: left;
      width: 26rem;
      font-size: 0.7rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
      width: 15rem;
      line-height: 1.2;
    }

    @media only screen and (max-width: 460px) {
      width: 19rem;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
      font-size: .6rem;
    }
  }
}

.blobtwo {
  position: relative;
  right: -80%;
  top: -90rem;
  width: 1000px;
  height: 1000px;
  animation: move 22s ease-in-out infinite;
  transform-origin: 50% 50%;
  z-index: -1;

  @media only screen and (max-width: 1180px) {
    right: -75%;
    top: -75rem;
  }

  @media only screen and (max-width: 980px) {
    top: -65rem;
    right: -70%;
    width: 700px;
    height: 700px;
  }

  @media only screen and (max-width: 850px) {
    top: -55rem;
  }

  @media only screen and (max-width: 600px) {
    top: -120rem;
    right: -60%;
    width: 800px;
    height: 800px;
  }

  @media only screen and (max-width: 500px) {
    top: -80rem;
    right: -40%;
    width: 600px;
    height: 600px;
  }

  @media only screen and (max-width: 460px) {
    top: -100rem;
    right: -60%;
    width: 500px;
    height: 500px;
  }

  @media only screen and (max-width: 320px) {
    top: -85rem;
    right: -60%;
    width: 400px;
    height: 400px;
  }
}
