.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5rem;
  margin-top: 10rem;

  @media only screen and (max-width: 1024px) and (max-height: 1366px) {
    margin-top: 15rem;
  }

  @media only screen and (max-width: 1024px) and (max-height: 640px) {
    margin-top: 32rem;
  }

  &__section {
    max-height: 100vh;
    margin-top: 30rem;

    @media only screen and (max-width: 1920px) {
      margin-top: 12rem;
    }

    
  

    @media only screen and (max-width: 850px) {
      margin-top: 0rem;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 2rem;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 65rem;
    }

    @media only screen and (max-width: 500px) {
      margin-top: 78rem;
    }

    @media only screen and (max-width: 460px) {
      margin-top: 48rem;
    }

    @media only screen and (max-width: 355px) {
      margin-top: 38rem;
    }

    @media only screen and (max-width: 320px) {
      margin-top: 37rem;
    }
  }

  &__heading {
    display: flex;
    align-self: flex-start;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 980px) {
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 700px) {
      margin-bottom: 1rem;
      margin-left: 0rem;
    }

    @media only screen and (max-width: 600px) {
      margin-bottom: 1rem;
      margin-left: 2.5rem;
    }

    @media only screen and (max-width: 500px) {
      margin-left: 2.7rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-left: 2rem;
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 320px) {
     font-size: .5rem;
    }
  }
}

.a-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  // Iphone X
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  &__selfie {
    background-image: url("../../images/helenabw.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 20rem;
    height: 24rem;
    align-self: flex-start;
    margin-right: 3rem;
    margin-top: -0.5rem;

    @media only screen and (max-width: 1180px) {
      width: 17rem;
      height: 20rem;
    }

    @media only screen and (max-width: 700px) {
      width: 14rem;
      height: 19rem;
      margin-right: 2rem;
    }

    @media only screen and (max-width: 600px) {
      width: 29rem;
      height: 16rem;
      background-position-y: -50px;
      margin-left: 2rem;
    }

    @media only screen and (max-width: 500px) {
      width: 22rem;
      height: 16rem;
      background-position-y: -50px;
      align-self: center;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-left: 2rem;
      width: 18rem;
      height: 14rem;
      background-position: 50%;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
      height: 11rem;
      background-position-y: -30px;
    }
  }

  &__logo {
    width: 20rem;
    height: 10rem;

    @media only screen and (max-width: 1180px) {
      width: 12rem;
      height: 7rem;
      margin-top: 0.5rem;
    }

    @media only screen and (max-width: 850px) {
      width: 10rem;
      margin-bottom: -1rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 45rem;
    margin-top: -3rem;

    @media only screen and (max-width: 1180px) {
      width: 40rem;
    }

    @media only screen and (max-width: 980px) {
      width: 30rem;
    }

    @media only screen and (max-width: 850px) {
      width: 22rem;
    }

    @media only screen and (max-width: 700px) {
      width: 20rem;
    }

    @media only screen and (max-width: 600px) {
      width: 29rem;
      margin-top: -1.5rem;
    }

    @media only screen and (max-width: 500px) {
      width: 22rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 18rem;
      margin-top: -2rem;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
    }

    p {
      font-size: 1rem;
      letter-spacing: 1px;
      font-weight: 500;
      margin-bottom: 1rem;
      margin-top: -1.5rem;

      @media only screen and (max-width: 1180px) {
        font-size: 0.8rem;
        margin-top: -1rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 850px) {
        line-height: 1.2;
      }

      // Iphone X
      @media only screen and (max-width: 414px) {
        font-size: 0.7rem;
        margin-top: -0.6rem;
        letter-spacing: 0;
      }
    }

    &--of {
      font-size: 0.7rem;
      font-weight: 600;
      margin-top: -0.3rem;

      @media only screen and (max-width: 1180px) {
        margin-top: -0.6rem;
        font-size: 0.6rem;
      }

      @media only screen and (max-width: 980px) {
        font-size: 0.5rem;
        font-weight: 500;
        margin-top: -0.4rem;
      }

      // Iphone X
      @media only screen and (max-width: 414px) {
        margin-top: -0.6rem;
      }
    }
  }

  &__links {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1.2rem;
    color: $color-purple;

    @media only screen and (max-width: 1180px) {
      margin-top: 2.5rem;
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 850px) {
      margin-top: 1.5rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-top: 0.5rem;
      letter-spacing: 1px;
    }

    @media only screen and (max-width: 320px) {
      font-size: .7rem;
    }

    &--item {
      margin-top: 1rem;
      margin-right: 2rem;

      // Iphone X
      @media only screen and (max-width: 414px) {
        margin-right: 3rem;
      }

      @media only screen and (max-width: 320px) {
        margin-right: 1rem;
      }
    }

  

    ul {
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: 700px) {
        margin-top: -0.7rem;
      }

      // Iphone X
      @media only screen and (max-width: 414px) {
        margin-top: -0.9rem;
      }
    }

    li {
      list-style: none;
    }
  }
}

.a-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 13.6rem);
    grid-template-rows: 14rem;

    @media only screen and (max-width: 1180px) {
      grid-template-columns: repeat(5, 12rem);
      grid-template-rows: 11rem;
    }

    @media only screen and (max-width: 980px) {
      grid-template-columns: repeat(5, 10.05rem);
      grid-template-rows: 10rem;
    }

    @media only screen and (max-width: 850px) {
      grid-template-columns: repeat(5, 8.4rem);
      grid-template-rows: 8rem;
    }

    @media only screen and (max-width: 700px) {
      grid-template-columns: repeat(5, 7.2rem);
      grid-template-rows: 7rem;
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(5, 6rem);
      grid-template-rows: 6rem;
      margin-top: -2rem;
    }

    @media only screen and (max-width: 500px) {
      grid-template-columns: repeat(5, 4.5rem);
      grid-template-rows: 4.5rem;
      margin-top: -2rem;
    }

    @media only screen and (max-width: 414px) {
      grid-template-columns: repeat(5, 3.6rem);
      grid-template-rows: 4rem;
      margin-top: -2rem;
    }

    @media only screen and (max-width: 320px) {
      grid-template-columns: repeat(5, 3.3rem);
      grid-template-rows: 3.3rem;
      margin-top: -2rem;
    }

    &--1 {
      grid-column: 1 / 1;
      grid-row: 1 / 1;
      background-image: url("../../images/greens.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 90% 90%;
    }

    &--2 {
      grid-column: 2 / 2;
      grid-row: 1 / 1;
      background-image: url("../../images/triangle.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    &--3 {
      grid-column: 3 / 3;
      grid-row: 1 / 1;
      background-image: url("../../images/selfie.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 10% 5%;
    }

    &--4 {
      grid-column: 4 / 4;
      grid-row: 1 / 1;
      background-image: url("../../images/geoeye.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    &--5 {
      grid-column: 5 / 5;
      grid-row: 1 / 1;
      background-image: url("../../images/triangles.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
}

.blobb {
  position: relative;
  top: -60rem;
  left: -30%;
  width: 1000px;
  height: 1000px;
  animation: movetwo 22s ease-in-out infinite;
  transform-origin: 50% 50%;
  z-index: -1;

  @media only screen and (max-width: 980px) {
    top: -55rem;
    left: -20%;
    width: 800px;
    height: 800px;
  }

  @media only screen and (max-width: 700px) {
    top: -50rem;
    left: -15%;
    width: 700px;
    height: 700px;
  }

  @media only screen and (max-width: 500px) {
    top: -60rem;
    left: -15%;
    width: 600px;
    height: 600px;
  }

  @media only screen and (max-width: 414px) {
    top: -50rem;
    left: 6%;
    width: 470px;
    height: 470px;
  }
}
