.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -3rem;

  @media only screen and (max-width: 1134px) and (max-height: 712px) {
    margin-top: 10rem;
  }

  @media only screen and (max-width: 1024px) and (max-height: 640px) {
    margin-top: 45rem;
  }




  &__section {
    max-height: 30vh;
    margin-top: 20rem;


    @media only screen and (max-width: 1920px) {
      margin-top: 20rem;
    }

    @media only screen and (max-width: 1400px) {
      margin-top: 18rem;
    }

    @media only screen and (max-width: 1180px) {
      margin-top: 14rem;
    }

    @media only screen and (max-width: 960px) {
      margin-top: 20rem;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 12rem;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 10rem;
    }

    @media only screen and (max-width: 340px) {
    margin-top: 18rem;
    }

    @media only screen and (max-width: 414px) {
      max-height: 20vh;
    }

    @media only screen and (max-width: 355px) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: 320px) {
      margin-top: 10rem;
    }
  }
}

.blobbis {
  position: relative;
  top: -65rem;
  right: 10%;
  width: 1000px;
  height: 1000px;
  animation: move 22s ease-in-out infinite;
  transform-origin: 50% 50%;
  z-index: -1;

  @media only screen and (max-width: 1180px) {
    width: 900px;
    height: 900px;
    right: 15%;
  }

  @media only screen and (max-width: 960px) and (max-height: 540) {
    width: 800px;
    height: 800px;
    right: 20%;
    top: -40rem;
  }

  

  

  // Iphone X
  @media only screen and (max-width: 414px) {
    width: 500px;
    height: 500px;
    right: 5%;
    top: -40rem;
  }
}

.footernav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 0.5rem 2rem;
  background-color: #000000;
  color: #ffffff;

  // Iphone X
  @media only screen and (max-width: 414px) {
    padding: 0.5rem 1rem;
  }

  &__info {
    font-size: 0.6rem;
    font-weight: 500;

    // Iphone X
    @media only screen and (max-width: 414px) {
      font-size: 0.5rem;
    }

    p {
      margin-top: 0.3rem;
    }

    &--copyright {
      font-size: 0.4rem;
      margin-top: 1rem;
    }
  }

  &__logo {
    width: 7rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 5rem;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }

  &__adress {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 0.7rem;
    font-weight: 500;
    margin-right: 2rem;

    // Iphone X
    @media only screen and (max-width: 414px) {
      //flex-direction: row;
      margin-right: 0;
    }

    &--image {
      background-image: url("../../images/selfie.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 7rem;
      width: 7rem;
      margin-bottom: 0.3rem;
      margin-top: -0.5rem;
      border-radius: 50px;

       // Iphone X
    @media only screen and (max-width: 414px) {
      width: 5rem;
      height: 5rem;
      margin-bottom: 0;
      margin-top: 0;
    }
    }
  }
}
