.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  &__section {
    margin-top: 10rem;
    max-height: 100vh;

    @media only screen and (max-width: 1134px) and (max-height: 712px) {
      margin-top: 6rem;
    }

    @media only screen and (max-width: 960px) and (max-height: 540px) {
      margin-top: 4rem;
    }
  

    @media only screen and (max-width: 850px) {
      margin-left: 4.5rem;
    }

   

    @media only screen and (max-width: 768px) {
      margin-left: 3rem;
      max-height: 80vh;
    }

    @media only screen and (max-width: 600px) {
      margin-left: 12rem;
    }

    @media only screen and (max-width: 500px) {
      margin-left: 18rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-left: 5.5rem;
    }

    @media only screen and (max-width: 340px) {
      margin-top: 4rem;
      margin-left: 7rem;
    }

    @media only screen and (max-width: 320px) {
      margin-top: 4rem;
      margin-left: 12rem;
      max-height: 5´70vh;
    }
  }

  &__logo {
    width: 40rem;

    @media only screen and (max-width: 1400px) {
      width: 35rem;
    }

    @media only screen and (max-width: 1024px) and (max-height: 640px) {
      width: 30rem;
    }


    @media only screen and (max-width: 960px) {
      width: 22rem;
      margin-top: 4rem;
    }

    @media only screen and (max-width: 768px) {
      width: 22rem;
      margin-top: 4rem;
    }


    @media only screen and (max-width: 600px) {
      width: 20rem;
    }

    // Iphone X
    @media only screen and (max-width: 500px) {
      width: 18.5rem;
    }

    @media only screen and (max-width: 340px) {
      width: 14rem;
      display: flex;
      align-self: center;
    }

    @media only screen and (max-width: 320px) {
      width: 12rem;
      display: flex;
      align-self: center;
    }
  }

  &__text {
    text-align: left;
    z-index: 1;
    font-size: 1.5rem;
    font-weight: 500;
    width: 60rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1400px) {
      font-size: 1.3rem;
    }

    @media only screen and (max-width: 1180px) {
      font-size: 1.2rem;
      width: 50rem;
    }

    @media only screen and (max-width: 960px) and (max-height: 540px) {
      font-size: 1.1rem;
      width: 40rem;
      margin-bottom: .3rem;
      margin-top: -1rem;
    }
   
    @media only screen and (max-width: 768px) {
      width: 35rem;
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
    }

    // Iphone X
    @media only screen and (max-width: 500px) {
      width: 20rem;
    }

    @media only screen and (max-width: 355px) {
      width: 16rem;
      font-size: .8rem;
    }

    @media only screen and (max-width: 320px) {
      width: 14rem;
    }
  }

  &__button {
    display: flex;
    align-self: flex-start;
    font-size: 1.9rem;
    font-weight: 600;
    text-transform: uppercase;
    border: 5px solid black;
    padding: 1rem 4rem;
    background-color: white;
    width: 23rem;
    margin-top: 2rem;
    transition: 0.25s;
    text-align: center;

    @media only screen and (max-width: 1400px) {
      font-size: 1.7rem;
      width: 22rem;
    }
    

    @media only screen and (max-width: 1180px) {
      font-size: 1.6rem;
      width: 21rem;
    }

    @media only screen and (max-width: 1024px) and (max-height: 640px) {
      width: 19rem;
      font-size: 1.4rem;
    }

    @media only screen and (max-width: 960px) and (max-height: 540px) {
      font-size: 1rem;
      width: 16rem;
      padding: .5rem 4rem;
      margin-top: 1rem;
    }

    @media only screen and (max-width: 768px) {
      font-size: 1rem;
      width: 16rem;
    }

    @media only screen and (max-width: 600px) {
      width: 25rem;
      padding: .5rem 8rem;
    }

    // Iphone X
    @media only screen and (max-width: 500px) {
      width: 19rem;
      font-size: 1rem;
      padding: .5rem 5rem;
      text-align: center;
    }

    @media only screen and (max-width: 340px) {
      width: 15rem;
      font-size: .7rem;
      padding: .5rem 4.5rem;
    }


    @media only screen and (max-width: 320px) {
      width: 12rem;
      font-size: .6rem;
      padding: .5rem 3.4rem;
      margin-top: 1rem;
    }

    &:hover,
    &:after,
    &:before {
      cursor: pointer;
      color: $color-pink;
      border-color: $color-pink;
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    }

    &:after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }
  }

  &__iconbox {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1134px) and (max-height: 712px) {
      margin-top: -3rem;
    }

    @media only screen and (max-width: 768px) {
      margin-right: 8rem;
    }

    @media only screen and (max-width: 500px) {
      margin-right: 19rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-right: 5.5rem;
    }

    @media only screen and (max-width: 355px) {
      margin-right: 8rem;
    }

    @media only screen and (max-width: 320px) {
      margin-right: 12rem;
      margin-top: -3rem;
    }
  }
}

.fa {
  margin-top: 5rem;
  -webkit-animation: bounce 2s 10;
  animation: bounce 2s infinite;
  transform: translateY(1200px);

  @media only screen and (max-width: 320px) {
    margin-top: 4.5rem;
  }
}

.blob {
  position: relative;
  left: -55%;
  top: -40rem;
  width: 900px;
  height: 900px;
  animation: move 17s ease-in-out infinite;
  transform-origin: 50% 50%;
  z-index: -1;

  @media only screen and (max-width: 1180px) {
    width: 800px;
    height: 800px;
  }

  @media only screen and (max-width: 980px) {
    width: 700px;
    height: 700px;
    left: -45%;
    top: -35rem;
  }

  @media only screen and (max-width: 768px) {
    width: 600px;
    height: 600px;
    left: -40%;
    top: -35rem;
  }

  @media only screen and (max-width: 500px) {
    width: 600px;
    height: 600px;
    left: -40%;
    top: -40rem;
  }

  // Iphone X
  @media only screen and (max-width: 414px) {
    width: 400px;
    height: 400px;
    left: -15%;
    top: -35rem;
  }
}

.blobone {
  position: relative;
  left: 110%;
  top: -110rem;
  width: 800px;
  height: 800px;
  animation: movetwo 17s ease-in-out infinite;
  transform-origin: 50% 50%;
  z-index: -1;

  @media only screen and (max-width: 1180px) {
    left: 95%;
    top: -100rem;
    width: 700px;
    height: 700px;
  }

  @media only screen and (max-width: 980px) {
    width: 600px;
    height: 600px;
    top: -90rem;
    left: 90%;
  }

  @media only screen and (max-width: 768px) {
    width: 500px;
    height: 500px;
    left: 90%;
    top: -80rem;
  }

  @media only screen and (max-width: 500px) {
    width: 400px;
    height: 400px;
    left: 60%;
    top: -50rem;
  }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 300px;
      height: 300px;
      left: 60%;
      top: -40rem;
    }

    @media only screen and (max-width: 320px) {
     top: -35rem;
      left: 60%;
    
    }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $color-pink;
  }
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  text-decoration: none;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
}

.btn:hover,
.btn-dark {
  cursor: pointer;
}

.btn-dark:hover {
  cursor: pointer;
}

#btn-start {
  transform: translateY(1200px);
  animation: btnstart 3.5s forwards ease-in 1.5s;
}

/* Keyframes */
@keyframes btnstart {
  to {
    transform: translateX(0);
  }
}

a,
:before,
:after {
  text-decoration: none;
  color: inherit;
}

.clients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
