@font-face {
  font-family: "Shutterlocks Slant";
  src: local("Shutterlocks Slant"),
    url(../../fonts/ShutterlocksSlant.ttf) format("truetype");
}

@font-face {
  font-family: "Shutterlocks";
  src: local("Shutterlocks"),
    url(../../fonts/Shutterlocks.ttf) format("truetype");
}

@font-face {
  font-family: "Elephant";
  src: local("ELEPHNT"), url(../../fonts/ELEPHNT.TTF) format("truetype");
}

.clientbanner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  &__bg {
    display: flex;
    background-color: #333;
    width: 100vw;
    height: 20vh;
    padding: 3rem;


    @media only screen and (max-width: 1180px) {
      height: 18vh;
    }

    @media only screen and (max-width: 960px) {
      height: 24vh;
    }

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      height: 43vh;
    }

    @media only screen and (max-width: 500px) {
      height: 36vh;
    }

    @media only screen and (max-width: 360px) {
      height: 45vh;
    }

    @media only screen and (max-width: 320px) {
      height: 36vh;
    }
  }

  &__heading {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $color-yellow;
    margin-top: -2.5rem;
    margin-left: -1.5rem;

    @media only screen and (max-width: 375px) {
    
      font-size: .7rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-left: -2rem;

    }

    @media only screen and (max-width: 320px) {
      font-size: .5rem;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    font-size: 3.2rem;
    line-height: 0.9;
    font-weight: 700;
    letter-spacing: 3px;
    font-family: "Shutterlocks Slant";
    text-align: center;
    margin-top: 4.5rem;
    width: 100%;

    @media only screen and (max-width: 1134px) and (max-height: 712px) {
      margin-top: 3.5rem;
    }

    @media only screen and (max-width: 1600px) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: 1380px) {
      font-size: 1.6rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 1.4rem;
    }

    

    // Iphone X
    @media only screen and (max-width: 600px) {
      font-size: 2.2rem;
      margin-top: 0.7rem;
      letter-spacing: 0;
      font-weight: 400;
      justify-content: center;
    }

    @media only screen and (max-width: 500px) {
      font-size: 1.9rem;
    }

    @media only screen and (max-width: 320px) {
      font-size: 1.4rem;
    }

    &:hover {
      cursor: pointer;
    }

    &--1 {
      -ms-transform: rotate(-10deg); /* IE 9 */
      transform: rotate(-10deg);

      @media only screen and (max-width: 768px) {
        margin-left: -5rem;
      }

      

      @media only screen and (max-width: 600px) {
        -ms-transform: none; /* IE 9 */
        transform: none;
        margin-left: 0;
        margin-bottom: -1rem;
        margin-top: 1rem;
      }

      @media only screen and (max-width: 375px) {
        margin-top: .5rem;
      }

      @media only screen and (max-width: 320px) {
        margin-top: 0rem;
      }

      &:hover {
        color: $color-pink;
      }
    }

    &--2 {
      -ms-transform: rotate(-10deg); /* IE 9 */
      transform: rotate(-10deg);

      @media only screen and (max-width: 600px) {
        -ms-transform: none; /* IE 9 */
        transform: none;
        margin-top: -1.1rem;
      }

      @media only screen and (max-width: 320px) {
        margin-top: -1.5rem;
      }

      &:hover {
        color: $color-orange;
      }
    }

    &--3 {
      -ms-transform: rotate(-10deg); /* IE 9 */
      transform: rotate(-10deg);

      @media only screen and (max-width: 600px) {
        -ms-transform: none; /* IE 9 */
        transform: none;
        margin-top: -1rem;
      }

      @media only screen and (max-width: 375px) {
        margin-top: -2rem;
      }

      @media only screen and (max-width: 320px) {
        margin-top: -2.5rem;
      }

      &:hover {
        color: $color-yellow;
      }
    }

    &--4 {
      -ms-transform: rotate(-10deg); /* IE 9 */
      transform: rotate(-10deg);

      @media only screen and (max-width: 768px) {
        margin-right: -3rem;
      }

      @media only screen and (max-width: 600px) {
        -ms-transform: none; /* IE 9 */
        transform: none;
        margin-top: -1rem;
      }

      @media only screen and (max-width: 375px) {
        margin-top: -2rem;
        margin-right: 0;
      }

      @media only screen and (max-width: 320px) {
        margin-top: -2.5rem;
      }

      &:hover {
        color: $color-purple;
      }
    }
  }
}
