@font-face {
  font-family: "Shutterlocks Slant";
  src: local("Shutterlocks Slant"),
    url(../../fonts/ShutterlocksSlant.ttf) format("truetype");
}

@font-face {
  font-family: "Shutterlocks";
  src: local("Shutterlocks"),
    url(../../fonts/Shutterlocks.ttf) format("truetype");
}

@font-face {
  font-family: "Elephant";
  src: local("ELEPHNT"), url(../../fonts/ELEPHNT.TTF) format("truetype");
}

.modal-btn {
  border: none;
  background: none;
  padding: 3rem 12rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -5.5rem;

  @media only screen and (max-width: 1600px) {
    padding: 2rem 10rem;
  }

  @media only screen and (max-width: 1380px) {
    padding: 2.5rem 6rem;
  }

  @media only screen and (max-width: 1380px) {
    padding: 2.5rem 6rem;
  }

  @media only screen and (max-width: 850px) {
    padding: 2.5rem 5rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 2.5rem 4rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 1.3rem 6rem 1.3rem;
    top: -2.9rem;
  }


  &:before,
  &:after,
  &:focus {
    border-style: none;
    border: none;
    border-color: none;
    text-decoration: none;
    outline: none;
  }
}

.infoModal {
  max-width: 1250px;
  max-height: 1000px;
  width: 100%;

  @media only screen and (max-width: 1300px) {
    max-width: 840px;
    max-height: 600px;
    width: 100%;
  }

  @media only screen and (max-width: 850px) {
    max-width: 750px;
    max-height: 600px;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    overflow: scroll;
  }

  // Iphone X
  @media only screen and (max-width: 600px) {
    max-width: 300px;
    max-height: 300px;
  }
}

.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-grid {
  display: grid;
  grid-template-columns: repeat(4, 18rem);
  grid-template-rows: 10rem 10rem 10rem 10rem;
  padding: 2rem;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(4, 12rem);
    grid-template-rows: 6rem 6rem 6rem 6rem;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: repeat(4, 10rem);
    grid-template-rows: 5rem 5rem 5rem 5rem;
  }

  // Iphone X
  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem;
  }

  &__1 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    &--heading {
      text-align: center;
      line-height: 1.3;
      margin-top: 1.5rem;
      font-family: "Elephant";
      font-size: 1.2rem;
      color: $color-pink;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        letter-spacing: 1px;
      }

      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

      // Iphone X
      @media only screen and (max-width: 600px) {
        font-size: 0.5rem;
        letter-spacing: 0.5px;
      }
    }
  }

  &__2 {
    grid-column: 1 / 3;
    grid-row: 2 / 5;
    margin-top: 0.5rem;

    @media only screen and (max-width: 768px) {
      margin-top: -1rem;
    }

    // Iphone X
    @media only screen and (max-width: 600px) {
      margin-top: 1rem;
    }

    &--link {
      font-weight: 600;
      color: $color-pink;
      cursor: pointer;
    }

    p {
      margin-bottom: 1rem;
      padding-right: 2rem;
      font-size: 1.2rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 768px) {
        padding-right: 0;
        padding: 1rem;
        margin-bottom: 0;
        margin-top: -1rem;
      }

      // Iphone X
      @media only screen and (max-width: 600px) {
        padding: 0.5rem;
        margin-top: -0.6rem;
      }
    }

    span {
      font-weight: 600;
    }
  }

  &__3 {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
    background-image: url("../../images/skorstorpbw.jpg");
    background-size: cover;
    background-position: 20%;
    background-repeat: no-repeat;

    // Iphone X
    @media only screen and (max-width: 600px) {
      width: 14rem;
      height: 8rem;
      margin-top: .5rem;
    }
  }

  &__4 {
    grid-column: 3 / 4;
    grid-row: 3 / 5;
    background-image: url("../../images/app.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10%;

      // Iphone X
      @media only screen and (max-width: 600px) {
        width: 14rem;
        height: 8rem;
        //margin-top: .5rem;
      }
  }

  &__5 {
    grid-column: 4 / 5;
    grid-row: 3 / 5;
    background-image: url("../../images/halacious-tZc3vjPCk-Q-unsplash.jpg");
    background-size: cover;
    background-repeat: no-repeat;

      // Iphone X
      @media only screen and (max-width: 600px) {
        width: 14rem;
        height: 8rem;
        //margin-top: .5rem;
      }
  }

  &__6 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    &--heading {
      text-align: center;
      line-height: 1.3;
      //margin-top: 1.5rem;
      font-family: "Elephant";
      font-size: 1.2rem;
      color: $color-orange;
      padding: 1.5rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        letter-spacing: 1px;
      }

      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

      // Iphone X
      @media only screen and (max-width: 600px) {
        font-size: 0.5rem;
        letter-spacing: 0.5px;
      }
    }
  }

  &__7 {
    grid-column: 1 / 3;
    grid-row: 2 / 5;
    margin-top: 0.5rem;

    @media only screen and (max-width: 768px) {
      margin-top: 0.2rem;
    }

    // Iphone X
    @media only screen and (max-width: 600px) {
      margin-top: 1rem;
    }

    &--link {
      font-weight: 600;
      color: $color-orange;
      cursor: pointer;
    }

    p {
      margin-bottom: 1rem;
      padding-right: 2rem;
      font-size: 1.2rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 768px) {
        padding-right: 0;
        padding: 1rem;
        margin-bottom: 0;
        margin-top: -1rem;
      }

          // Iphone X
          @media only screen and (max-width: 600px) {
            padding: 0.5rem;
            margin-top: -2rem;
            margin-bottom: 2rem;
          }
    }
  }

  &__8 {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
    background-image: url("../../images/webgroup.jpg");
    background-size: cover;
    background-repeat: no-repeat;

     // Iphone X
     @media only screen and (max-width: 600px) {
      width: 14rem;
      height: 8rem;
      margin-top: -1rem;
    }
  }

  &__9 {
    grid-column: 3 / 5;
    grid-row: 3 / 5;
    background-image: url("../../images/webdev.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -45px;

     // Iphone X
     @media only screen and (max-width: 600px) {
      width: 14rem;
      height: 8rem;
      background-position-y: -30px;

    }
  }

  &__10 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    &--heading {
      text-align: center;
      line-height: 1.3;
      margin-top: 0.5rem;
      font-family: "Elephant";
      font-size: 1.2rem;
      color: #fdc200;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
        margin-top: -0.5rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        letter-spacing: 1px;
      }

      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

       // Iphone X
       @media only screen and (max-width: 600px) {
        font-size: 0.5rem;
        letter-spacing: 0.5px;
        margin-bottom: 1rem;
      }
    }
  }

  &__11 {
    grid-column: 1 / 3;
    grid-row: 2 / 5;
    margin-top: -4.5rem;

    @media only screen and (max-width: 768px) {
      margin-top: -2rem;
    }

     // Iphone X
     @media only screen and (max-width: 600px) {
      margin-top: 1rem;
    }

    p {
      margin-bottom: 1rem;
      padding-right: 2rem;
      font-size: 1.2rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 768px) {
        padding-right: 0;
        padding: 1rem;
        margin-bottom: 0;
        margin-top: -1rem;
      }

        // Iphone X
        @media only screen and (max-width: 600px) {
          padding: 0.5rem;
          margin-top: -2rem;
          margin-bottom: 1rem;
        }
    }

    span {
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $color-purple;
    }

    &--tuned {
      font-family: "Shutterlocks slant";
      font-size: 5rem;
      -ms-transform: rotate(-5deg); /* IE 9 */
      transform: rotate(-5deg);
      text-align: center;
      letter-spacing: 2px;
      color: $color-purple;

      @media only screen and (max-width: 1300px) {
        font-size: 3.6rem;
      }

        // Iphone X
        @media only screen and (max-width: 600px) {
          margin-top: -1.5rem;
          margin-bottom: 1rem;
        }
    }
  }

  &__12 {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
    background-image: url("../../images/logo-design.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10%;

     // Iphone X
     @media only screen and (max-width: 600px) {
      width: 14rem;
      height: 8rem;
      margin-top: -1rem;
    }
  }

  &__13 {
    grid-column: 3 / 4;
    grid-row: 3 / 5;
    background-image: url("../../images/lock.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10%;
    width: 25rem;
    z-index: 1;

    @media only screen and (max-width: 1300px) {
      width: 18rem;
    }

    @media only screen and (max-width: 850px) {
      width: 15rem;
    }

     // Iphone X
     @media only screen and (max-width: 600px) {
      width: 14rem;
      height: 8rem;
    }
  }

  &__14 {
    grid-column: 4 / 5;
    grid-row: 3 / 5;
    background-image: url("../../images/bulb.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 70px;
    background-position-y: -10px;

    @media only screen and (max-width: 1300px) {
      background-position-y: -4px;
    }

    @media only screen and (max-width: 850px) {
      background-position-y: -4px;
    }

     // Iphone X
     @media only screen and (max-width: 600px) {
      width: 14rem;
      height: 8rem;
      background-position-x: 0px;
      background-position-y: 50%;
    }
  }

  &__15 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    &--heading {
      text-align: center;
      line-height: 1.3;
      margin-top: 4rem;
      font-family: "Elephant";
      font-size: 1.2rem;
      color: $color-purple;
      letter-spacing: 2px;
      margin-left: -2rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
        margin-top: 0;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.6rem;
        letter-spacing: 1px;
      }

      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

        // Iphone X
        @media only screen and (max-width: 600px) {
          font-size: 0.5rem;
          letter-spacing: 1px;
          margin-bottom: 1rem;
        }
    }
  }

  &__16 {
    grid-column: 1 / 3;
    grid-row: 2 / 5;
    margin-top: 3rem;

    @media only screen and (max-width: 768px) {
      margin-top: -2rem;
    }

    @media only screen and (max-width: 1300px) {
      margin-top: -1rem;
    }

    p {
      margin-bottom: 1rem;
      padding-right: 2rem;
      font-size: 1.2rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 850px) {
        font-size: 0.7rem;
      }

      @media only screen and (max-width: 768px) {
        padding-right: 0;
        padding: 1rem;
        margin-bottom: 0;
        margin-top: -1rem;
      }

        // Iphone X
        @media only screen and (max-width: 600px) {
          padding: 0.5rem;
          margin-top: .2rem;
          margin-bottom: -.5rem;
        }
    }
  }

  &__17 {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
    background-image: url("../../images/lakejsweblogo.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 4rem;

    @media only screen and (max-width: 1300px) {
      margin-top: 1rem;
    }

      // Iphone X
      @media only screen and (max-width: 600px) {
        width: 14rem;
        height: 8rem;
        margin-top: 1rem;
      }
  }

  &__18 {
    grid-column: 3 / 5;
    grid-row: 3 / 5;
    background-image: url("../../images/fia-pattern-plate.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    @media only screen and (max-width: 1300px) {
      margin-top: -3rem;
    }

      // Iphone X
      @media only screen and (max-width: 600px) {
        width: 14rem;
        height: 8rem;
      }
  }
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
