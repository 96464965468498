.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.5rem 2.5rem;
  position: absolute;
  width: 100%;
  margin-bottom: 3rem;
  z-index: 1;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

   

  &__logo {
    width: 6rem;
    height: 3rem;
    margin-right: 2rem;
    animation: rotate 0.7s ease-in-out 0.5s;

    @media only screen and (max-width: 500px) {
      width: 5rem;
      height: 2rem;
    }

    &--holder {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

  }

  &__socialmedia {
    display: flex;
    list-style: none;

    &--links {
      margin-top: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  &__link {
    display: flex;
    list-style: none;


    &--item {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;

      cursor: pointer;

      @media only screen and (max-width: 1229px) and (min-width: 500px) {
        padding: 0;
      }

      &:hover {
        cursor: pointer;
        color: $color-pink;
      }
    }
  }
}

.navbar-sticky {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 2px #eee;
  animation: moveDown 0.5s ease-in-out;

  @media only screen and (max-width: 1299px) and (min-width: 500px) {
    flex-direction: column;
    font-size: .6rem;
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}

.facebook {
  width: 2.5rem;
  height: 2.5rem;

  @media only screen and (max-width: 1100px) {
    width: 2rem;
    height: 2.2rem;
  }

  @media only screen and (max-width: 989px) and (min-width: 500px) {
    width: 1.5rem;
    height: 2rem;
  }

  @media only screen and (max-width: 355px) {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  &__path {
    &:hover {
      fill: $color-pink;
      cursor: pointer;
    }
  }
}

.instagram {
  width: 2.5rem;
  height: 2.5rem;

  @media only screen and (max-width: 1100px) {
    width: 2rem;
    height: 2.2rem;
  }

  @media only screen and (max-width: 989px) and (min-width: 500px) {
    width: 1.5rem;
    height: 2rem;
  }

  @media only screen and (max-width: 355px) {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  &__path {
    &:hover {
      fill: $color-pink;
      cursor: pointer;
    }
  }
}

.linkedin {
  width: 2.5rem;
  height: 2.5rem;

  @media only screen and (max-width: 1100px) {
    width: 2rem;
    height: 2.2rem;
  }

  @media only screen and (max-width: 989px) and (min-width: 500px) {
    width: 1.5rem;
    height: 2rem;
  }

  @media only screen and (max-width: 355px) {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  &__path {
    &:hover {
      fill: $color-pink;
      cursor: pointer;
    }
  }
}

.behance {
  width: 2.5rem;
  height: 2.5rem;

  @media only screen and (max-width: 1100px) {
    width: 2rem;
    height: 2.2rem;
  }

  @media only screen and (max-width: 989px) and (min-width: 500px) {
    width: 1.5rem;
    height: 2rem;
  }

  @media only screen and (max-width: 355px) {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  &__path {
    &:hover {
      fill: $color-pink;
      cursor: pointer;
    }
  }
}

.btn-nav {
  display: inline-block;
  padding: 1rem 2rem;
  text-decoration: none;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;

  @media only screen and (max-width: 1160px) {
    padding: 1.5rem;
  }

  @media only screen and (max-width: 1100px) {
    padding: 1rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 989px) {
    font-size: 1rem;
    padding: 0.5rem;
  }

  @media only screen and (max-width: 845px) {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 760px) {
    font-size: 0.8rem;
    //padding: .3rem;
    padding: 0.7rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.7rem;
    padding: 0.7rem;
  }

  @media only screen and (max-width: 545px) {
    font-size: 0.7rem;
    padding: 0.4rem;
  }

  // Iphone X
  @media only screen and (max-width: 500px) {
    font-size: 0.6rem;
    letter-spacing: 0;
    padding: .2rem;
  }

  @media only screen and (max-width: 355px) {
    font-size: 0.5rem;
    letter-spacing: 0;
    padding: .3rem;
  }

  @media only screen and (max-width: 320px) {
    font-size: 0.45rem;
    letter-spacing: 0;
    padding: .3rem;
  }
}

.btn-nav:hover {
  cursor: pointer;
}

