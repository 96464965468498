.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 3rem;
  margin-top: 7rem;

  @media only screen and (max-width: 1024px) and (max-height: 1366px) {
    margin-top: 13rem;
  }

  @media only screen and (max-width: 1024px) and (max-height: 640px) {
    margin-top: 40rem;
  }

  @media only screen and (max-width: 960px) and (max-height: 540px) {
    margin-top: 48rem;
  }

  &__section {
    max-height: 100vh;
    margin-top: 30rem;

    @media only screen and (max-width: 1920px) {
      margin-top: 22rem;
    }

    @media only screen and (max-width: 1180px) {
      margin-top: 15rem;
    }

    @media only screen and (max-width: 850px) {
      margin-top: 8rem;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 10rem;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 12rem;
    }

    @media only screen and (max-width: 355px) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: 320px) {
      margin-top: 13rem;
    }
  }

  &__heading {
    display: flex;
    align-self: flex-start;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 2rem;

    @media only screen and (max-width: 960px) and (max-height: 540px) {
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 850px) {
      margin-left: 0.5rem;
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 700px) {
      margin-bottom: 0.5rem;
      margin-left: 1.5rem;
    }

    @media only screen and (max-width: 600px) {
      margin-bottom: 0.5rem;
      margin-left: 4.4rem;
    }

    @media only screen and (max-width: 500px) {
      margin-bottom: 0.5rem;
      margin-left: 7.9rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-left: 3.7rem;
      margin-bottom: 0.5rem;
    }

    @media only screen and (max-width: 320px) {
      margin-left: 4.6rem;
      font-size: 0.5rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 13.7rem);
    grid-template-rows: 5rem;

    @media only screen and (max-width: 1180px) {
      grid-template-columns: repeat(5, 12rem);
      grid-template-rows: 5rem;
    }

    @media only screen and (max-width: 980px) {
      grid-template-columns: repeat(5, 10rem);
      grid-template-rows: 5rem;
    }

    @media only screen and (max-width: 850px) {
      grid-template-columns: repeat(5, 8.5rem);
      grid-template-rows: 5rem;
    }

    @media only screen and (max-width: 700px) {
      grid-template-columns: repeat(5, 7rem);
      grid-template-rows: 5rem;
    }

    @media only screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $color-yellow;
      width: 29rem;
    }

    @media only screen and (max-width: 500px) {
      width: 22rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 18rem;
    }

    @media only screen and (max-width: 320px) {
      width: 16rem;
    }

    &--1 {
      grid-column: 1 / 6;
      grid-row: 1 / 20;
      background-color: $color-yellow;
      //margin-right: -1.6rem;

      @media only screen and (max-width: 1180px) {
        grid-row: 1 / 10;
      }

      @media only screen and (max-width: 850px) {
        grid-row: 1 / 8;
      }
    }

    &--2 {
      grid-column: 2 / 4;
      grid-row: 1 / 10;
      background-image: url("../../images/helenabw01.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      margin-left: -14rem;
      height: 33rem;
      width: 28rem;
      margin-top: 7rem;

      @media only screen and (max-width: 1180px) {
        height: 32.6rem;
        width: 24rem;
        margin-top: 4rem;
      }

      @media only screen and (max-width: 1060px) {
        margin-left: -12rem;
      }

      @media only screen and (max-width: 980px) {
        height: 27.7rem;
        width: 18rem;
        margin-left: -10rem;
      }

      @media only screen and (max-width: 850px) {
        height: 26rem;
        width: 16rem;
      }

      @media only screen and (max-width: 750px) {
        margin-left: -8.5rem;
      }

      @media only screen and (max-width: 700px) {
        height: 25.2rem;
        width: 15rem;
        background-position: 50%;
        margin-top: 2rem;
      }

      @media only screen and (max-width: 640px) {
        margin-left: -7rem;
      }

      @media only screen and (max-width: 600px) {
        height: 10rem;
        width: 28rem;
        background-position-y: 25%;
        margin-top: 0.5rem;
        margin-left: 0;
      }

      @media only screen and (max-width: 500px) {
        height: 10rem;
        width: 21rem;
        background-position-y: 25%;
        margin-top: 0.5rem;
        margin-left: 0;
      }

      // Iphone X
      @media only screen and (max-width: 414px) {
        height: 8rem;
        width: 17rem;
        background-position-y: 20%;
      }

      @media only screen and (max-width: 320px) {
        width: 15rem;
        height: 7rem;
      }
    }

    &--3 {
      grid-column: 3 / 5;
      grid-row: 1 / 5;

      // Iphone X
      @media only screen and (max-width: 600px) {
        margin-top: -1.5rem;
      }
    }
  }
}

.blobbb {
  position: relative;
  top: -80rem;
  left: 60%;
  width: 1200px;
  height: 1200px;
  animation: move 22s ease-in-out infinite;
  transform-origin: 50% 50%;
  z-index: -1;

  @media only screen and (max-width: 1180px) {
    width: 1000px;
    height: 1000px;
  }

  @media only screen and (max-width: 960px) and (max-height: 540px) {
    width: 700px;
    height: 700px;
    top: -85rem;
  }

  @media only screen and (max-width: 850px) {
    width: 700px;
    height: 700px;
    top: -60rem;
  }

  // Iphone X
  @media only screen and (max-width: 414px) {
    width: 500px;
    height: 500px;
    top: -50rem;
  }
}

@font-face {
  font-family: "Elephant";
  src: local("ELEPHNT"), url(../../fonts/ELEPHNT.TTF) format("truetype");
}

.contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  margin-left: 14rem;

  @media only screen and (max-width: 1180px) {
    margin-left: 12rem;
  }

  @media only screen and (max-width: 980px) {
    margin-left: 8rem;
  }

  @media only screen and (max-width: 850px) {
    margin-left: 7.5rem;
  }

  @media only screen and (max-width: 700px) {
    margin-left: 7rem;
    padding: 2rem;
  }

  // Iphone X
  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }

  &__infobg {
    background-color: #ffffff;
    padding: 2rem;
    width: 32rem;

    @media only screen and (max-width: 980px) {
      width: 28rem;
    }

    @media only screen and (max-width: 850px) {
      width: 22rem;
      padding: 1rem;
    }

    @media only screen and (max-width: 700px) {
      width: 18rem;
      padding: 1rem;
    }

    @media only screen and (max-width: 600px) {
      width: 28rem;
      padding: 1rem;
    }

    @media only screen and (max-width: 500px) {
      width: 21rem;
      padding: 1rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 17rem;
      padding: 0.8rem;
    }

    @media only screen and (max-width: 320px) {
      width: 15rem;
    }
  }

  &__heading {
    font-family: "Elephant";
    font-size: 2rem;
    letter-spacing: 1.5px;
    text-align: center;
    margin-top: -1rem;

    @media only screen and (max-width: 1100px) {
      font-size: 1.6rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 1.3rem;
    }

    @media only screen and (max-width: 850px) {
      margin-top: -0.5rem;
    }

    @media only screen and (max-width: 700px) {
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      font-size: 0.9rem;
    }
  }

  &__info {
    font-size: 1rem;
    line-height: 1.1;
    letter-spacing: 1px;
    margin-top: 0.5rem;

    @media only screen and (max-width: 1100px) {
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.7rem;
      margin-top: 0;
    }

    @media only screen and (max-width: 850px) {
      margin-top: -0.5rem;
    }

    @media only screen and (max-width: 700px) {
      margin-top: -0.7rem;
    }

    @media only screen and (max-width: 600px) {
      font-size: 0.8rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      font-size: 0.73rem;
      line-height: 1;
      letter-spacing: 0;
    }
  }

  &__form {
    padding: 0.2rem;

    @media only screen and (max-width: 700px) {
      padding: 0.1rem;
    }
  }

  &__item {
    padding: 0.3rem 0;

    @media only screen and (max-width: 700px) {
      padding: 0.2rem;
    }
  }

  &__input {
    width: 32rem;
    font-size: 0.8rem;
    padding: 0.5rem;
    font-family: "Poppins", sans-serif;
    border: none;
    font-weight: 500;

    @media only screen and (max-width: 1180px) {
      font-size: 0.7rem;
      margin-top: 0.3rem;
      padding: 0.2rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.6rem;
      margin-top: 0.2rem;
      width: 28rem;
    }

    @media only screen and (max-width: 850px) {
      width: 22rem;
    }

    @media only screen and (max-width: 700px) {
      width: 18rem;
    }

    @media only screen and (max-width: 600px) {
      width: 28rem;
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 500px) {
      width: 21rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 17rem;
    }

    @media only screen and (max-width: 320px) {
      width: 15rem;
    }
  }

  &__message {
    width: 32rem;
    font-size: 0.8rem;
    font-family: "Poppins", sans-serif;
    padding-top: 0.2rem;
    padding: 0.5rem;
    border: none;

    @media only screen and (max-width: 1180px) {
      font-size: 0.7rem;
      padding: 0.2rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 0.6rem;
      margin-top: 0.2rem;
      width: 28rem;
    }

    @media only screen and (max-width: 850px) {
      width: 22rem;
    }

    @media only screen and (max-width: 700px) {
      width: 18rem;
    }

    @media only screen and (max-width: 600px) {
      width: 28rem;
      font-size: 0.7rem;
    }

    @media only screen and (max-width: 500px) {
      width: 21rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 17rem;
    }

    @media only screen and (max-width: 320px) {
      width: 15rem;
    }
  }

  &__btn {
    width: 32rem;
    font-size: 1.8rem;
    letter-spacing: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    border: 5px solid #000000;
    background-color: #ffffff;
    transition: 0.25s;
    margin-top: 0.7rem;

    @media only screen and (max-width: 1180px) {
      font-size: 1.3rem;
    }

    @media only screen and (max-width: 980px) {
      font-size: 1rem;
      width: 28rem;
      margin-top: 0.2rem;
    }

    @media only screen and (max-width: 850px) {
      width: 22rem;
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 700px) {
      width: 18rem;
      margin-top: 0.1rem;
      margin-left: 0.2rem;
    }

    @media only screen and (max-width: 600px) {
      width: 28rem;
    }

    @media only screen and (max-width: 500px) {
      width: 21rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      width: 17rem;
    }

    @media only screen and (max-width: 320px) {
      width: 15rem;
    }

    &:hover,
    &:after,
    &:before {
      cursor: pointer;
      color: $color-purple;
      border-color: $color-purple;
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    }

    &:after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    font-weight: 600;

    @media only screen and (max-width: 980px) {
      margin-top: 0.6rem;
      font-weight: 500;
    }

    @media only screen and (max-width: 850px) {
      margin-top: 0.5rem;
    }

    @media only screen and (max-width: 700px) {
      margin-top: 0.6rem;
    }

    // Iphone X
    @media only screen and (max-width: 414px) {
      margin-top: 0.4rem;
    }
  }

  span {
    font-weight: 700;
    text-transform: uppercase;
    color: $color-purple;
    letter-spacing: 2px;
  }
}

.link-btn {
  cursor: pointer;
}
