.clients {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 2rem;

  &__nav {
      align-self: flex-start;

      &--link {
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 2px;
          margin-top: 1rem;
          text-align: center;
          background-color: $color-orange;
          color: #ffffff;
          padding: .3rem .7rem;

          span {
              margin-left: -.5rem;
          }

          &:hover {
              cursor: pointer;
              background-color: $color-pink;
          }
      }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(10, 10rem);
    grid-template-rows: 20rem 10rem;
    margin: 4rem;

    &--1 {
        grid-column: 1 / 5;
        grid-row: 1 / 1;
        background-color: green;
    }

    &--2 {
    }

    &--3 {
    }

    &--4 {
    }

    &--5 {
    }
  }
}

.arrow {
    width: 1.3rem;
    height: .8rem;
    position: relative;
    right: .4rem;
    top: .1rem;
}
