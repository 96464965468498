@keyframes move {
    0%   { transform: scale(1)   translate(-200px, -100px); }
    38%  { transform: scale(0.8, 1) translate(-180px, -160px) rotate(160deg); }
    40%  { tansform: scale(0.8, 1) translate(-180px, -160px) rotate(160deg); }
    78%  { transform: scale(1.1) translate(-210px, -100px) rotate(-10deg); }
    80%  { transform: scale(1.1) translate(-210px, -100px) rotate(-10deg); }
    100% { transform: scale(1)   translate(-200px, -100px); }
    }
  
    @keyframes movetwo {
      0%   { transform: scale(1)   translate(-200px, -100px); }
      38%  { transform: scale(0.8, 1) translate(-180px, -160px) rotate(80deg); }
      40%  { tansform: scale(0.8, 1) translate(-180px, -160px) rotate(80deg); }
      78%  { transform: scale(1.1) translate(-210px, -100px) rotate(-5deg); }
      80%  { transform: scale(1.1) translate(-210px, -100px) rotate(-5deg); }
      100% { transform: scale(1)   translate(-200px, -100px); }
      }

      @-webkit-keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
        }
        40% {
          -webkit-transform: translateY(-30px);
                  transform: translateY(-30px);
        }
        60% {
          -webkit-transform: translateY(-15px);
                  transform: translateY(-15px);
        }
      }
      @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
        }
        40% {
          -webkit-transform: translateY(-30px);
                  transform: translateY(-30px);
        }
        60% {
          -webkit-transform: translateY(-15px);
                  transform: translateY(-15px);
        }
      }

      @keyframes customEnterAnimation {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes customLeaveAnimation {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      

     